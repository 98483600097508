<template>
  <b-row>
    <b-col>
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-header
            class="justify-content-start p-0 pb-1 mb-1 border-bottom"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2">
              View Startups
            </h3>
          </b-card-header>
          <!-- search input -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group
              class="form-inline"
              label="Search"
              label-size="sm"
            >
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search startups"
                type="text"
              />
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    dropright
                    no-caret
                    size="sm"
                    toggle-class="text-decoration-none"
                    variant="link"
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        class="text-body align-middle"
                        icon="MoreVerticalIcon"
                        size="16"
                      />
                    </template>
                    <b-dropdown-item>
                      <feather-icon
                        class="mr-50"
                        icon="CopyIcon"
                      />
                      <span>View Reviews</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <feather-icon
                        class="mr-50"
                        icon="CopyIcon"
                      />
                      <span>Update Status</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>
<script>

import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
  BCardHeader,
  BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BCard,
    BCardHeader,
    BOverlay,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    // vSelect,
  },
  data() {
    return {
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Startup',
          field(row) {
            if (!row.programs_startupparticipant) return '-'
            return row.programs_startupparticipant.startups_basicinfo.title
          },
        }, {
          label: 'Recommendations',
          field: 'recommendations',
        }, {
          label: 'Status',
          field: 'status',
        },
      ],
      rows: [],
    }
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_progressstagetable_by_pk(id: ${this.$route.params.id}) {
            programs_progressrecordtables {
              recommendations
              status
              programs_startupparticipant {
                startups_basicinfo {
                  title
                }
              }
            }
          }
        }`
      },
      update: data => data.programs_progressstagetable_by_pk.programs_progressrecordtables,
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
